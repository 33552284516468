import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import AtlantaTemplate from '../../../templates/AtlantaTemplate'

const AtlantaLocation = () => {

	/*
	const accordionData = [
		{
			question: "What is Trailblazer Bootcamp?",
			answerHtml: "<p>With 6 days of expert-led learning, 10 role-based tracks, two onsite certification exams, and endless networking opportunities, Trailblazer Bootcamp is the place to build expertise and accelerate your path to certification.</p>"
		},
		{
			question: "Who should come to Trailblazer Bootcamp?",
			answerHtml: "<p>Trailblazer Bootcamp offers a variety of role-based tracks including: </p><ul><li>Administrator</li><li>Developer</li><li>Architect</li><li>Data Rockstar</li><li>Marketer</li></ul><p>We also welcome anyone who wants to explore new areas of interest in Salesforce, MuleSoft, and Tableau.</p>"
		},
		{
			question: "I’m planning to attend Trailblazer Bootcamp. Which track should I take?",
			answerHtml: "<p>Each track aligns with a specific role. Take a look at the detailed descriptions of our role-based tracks to see which suits you best. If you have questions, you can post them in the <a href='https://trailhead.salesforce.com/trailblazer-community/groups/0F94S000000kKjZSAU' target='_blank'>Trailblazer Bootcamp Atlanta</a> Community Group.</p>"
		},
		{
			question: "Is there prework for Trailblazer Bootcamp?",
			answerHtml: "<p>Yes. To ensure the best learning experience possible, we’ve identified some prework for you to complete before you arrive at Trailblazer Bootcamp. Navigate to your track <a href='https://trailhead.salesforce.com/help?article=Trailblazer-Bootcamp-Prework' target='_blank'>here</a> and click on the prework link for your track. BONUS: You’ll earn the exclusive Trailblazer Bootcamp Atlanta Community Badge when you complete your prework.</p>"
		},
		{
			question: "Will computers be provided at Trailblazer Bootcamp?",
			answerHtml: "<p>No. Computers are not provided at Trailblazer Bootcamp, so attendees will need to bring their own device.</p>"
		},
		{
			question: "Do I need to configure anything on my computer to get it set up for Trailblazer Bootcamp?",
			answerHtml: "<p>Yes. Taking an exam on your personal computer requires some setup. Follow the steps <a href='https://trailhead.salesforce.com/help?article=Guide-to-Trailblazer-Bootcamp' target='_blank'>here</a> to get your computer exam-ready.</p>"
		},
		{
			question: "Do I have to take an exam during Trailblazer Bootcamp?",
			answerHtml: "<p>Exams are optional. You may choose to receive a voucher and take your exam(s) after bootcamp. Vouchers will be sent via email post-event, and exam(s) must be taken before August 30, 2023, at 11:59 PM EST.</p>"
		},
		{
			question: "Is there a Community Group for Trailblazer Bootcamp Atlanta?",
			answerHtml: "<p>Yes. Get updates and post questions in the <a target='_blank' href='https://trailhead.salesforce.com/trailblazer-community/groups/0F94S000000kKjZSAU'>Trailblazer Bootcamp Atlanta</a> Community Group.</p>"
		},
		{
			question: "What is the cost of the Trailblazer Bootcamp pass?",
			answerHtml: "<p>Early bird pricing of $2,599 is available until 11:59 PM EST on February 28, 2023. Thereafter, the full Trailblazer Bootcamp pass cost is $2,999. Accommodations are not included in the price of the Trailblazer Bootcamp pass and may be purchased separately through the registration process.</p>"
		},
		{
			question: "Are hotel blocks available for the event?",
			answerHtml: "<p>Yes. Salesforce has reserved hotel blocks at the <a target='_blank' href='https://www.marriott.com/en-us/hotels/atlbc-atlanta-marriott-buckhead-hotel-and-conference-center/overview/'>Atlanta Marriott Buckhead Hotel & Conference Center</a>. This limited block of rooms is available at a pre-negotiated rate while supplies last. You’ll have the opportunity to book your room at the end of your registration process.</p>"
		},
		{
			question: "How do I request a visa invitation?",
			answerHtml: "<p>You can request a visa invitation letter during the registration process. Please note that you must complete your registration to secure your visa invitation.</p>"
		},
		{
			question: "What is the dress code?",
			answerHtml: "<p>Trailblazer Bootcamp attire is business casual. Pack layers and check the weather before you arrive.</p>"
		},
		{
			question: "What is the cancellation policy?",
			answerHtml: "<p>Cancellation Policy:</p><ul><li>Cancellation requests must be received in writing.</li><li>Cancellations are <b>final</b>.</li><li>Cancellation/refund deadlines apply:<ul><li>Full refund: Cancellation must be received by 11:59 PM EST on March 31, 2023. </li><li>50% refund: Cancellation must be received before 11:59 PM EST on April 15, 2023.</li><li>No refunds will be given after 12:00 AM EST on April 16, 2023.</li><li>Failure to check in at the event does not constitute cancellation and will not give rise to a refund or a credit.</li><li><a target='_blank' href='https://success.salesforce.com/Ev_SiteTOS'>Event Terms of Service</a></li></ul></li></ul>"
		},
		{
			question: "Who can I contact for assistance with registering for Trailblazer Bootcamp?",
			answerHtml: "<p>Please contact the Trailblazer Bootcamp Registration and Housing team: </p><ul><li>U.S./Canada: +18446101363 </li><li>International:+14159159075 </li><li>Email us: <a href='mailto:trailblazerbc2023atl@regsvc.com'>trailblazerbc2023atl@regsvc.com</a></li></ul>"
		},
		{
			question: "Does Trailblazer Bootcamp have a Code of Conduct?",
			answerHtml: "<p>Yes, check out our <a target='_blank' href='https://success.salesforce.com/ev_sitecoc'>Code of Conduct</a>. Have a safe and fun conference!</p>"
		}
	]

	const accordionStateData = accordionData.map(item => {item.id = uuidv4(); return item})
	*/
	
    return (
        <AtlantaTemplate>
			<div class="salesforce-footer"></div>
				<div class="heroContent">
					<div class="heroSky">
						<div class="hottieCenter">
							<img class="herohottie" src="https://developer.salesforce.com/resources2/bootcamps2022/Atlanta/Images/hero/2023_BC_Regional-Landing-Page_Hootie.gif" alt="background" />
						</div>
						<img class="heroballon" src="https://developer.salesforce.com/resources2/bootcamps2022/Atlanta/Images/hero/2023_BC_Regional-Landing-Page_USA-Balloon.gif" alt="background" />
					</div>
				</div>

				<section class="container-content">
					<h1 class="h1Internal">FAQ</h1>
					<h3>Bring on the questions.</h3>
					<h4 class="faqH4">General Info</h4>
					<div class="panel-group faq-panel" id="faq-accordion" role="tablist" aria-multiselectable="true">

						{
						/*accordionStateData.map(item => (
							<div class="panel panel-default">
								<div class="panel-heading" role="tab" id={`faq-item-${item.id}`}>
									<h4 class="panel-title">
										<a class="collapsed" role="button" data-toggle="collapse" data-parent="#faq-accordion" href={`#collapse-${item.id}`} aria-expanded="false" aria-controls={`collapse-${item.id}`}>
											<span class="glyphicon glyphicon-plus-sign"></span>
											<span class="glyphicon glyphicon glyphicon-minus-sign"></span>
											<div class="panel-full">{item.question}</div>
										</a>
									</h4>
								</div>
								<div id={`collapse-${item.id}`} class="panel-collapse collapse" role="tabpanel" aria-labelledby={`faq-item-${item.id}`}>
									<div class="panel-content">
										<div class="panel-row" dangerouslySetInnerHTML={{__html: item.answerHtml}} />
									</div>
								</div>
							</div>
						))*/
						}
						
						<div class="panel panel-default">
							<div class="panel-heading" role="tab" id="collapse-action-1">
								<h4 class="panel-title">
									<a class="collapsed" role="button" data-toggle="collapse" data-parent="#faq-accordion" href="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
										<span class="glyphicon glyphicon-plus-sign"></span>
										<span class="glyphicon glyphicon glyphicon-minus-sign"></span>
										<div class="panel-full">What is Trailblazer Bootcamp?</div>
									</a>
								</h4>
							</div>
							<div id="collapse-1" class="panel-collapse collapse" role="tabpanel" aria-labelledby="collapse-action-1">
								<div class="panel-content">
									<div class="panel-row">
										<p>With 6 days of expert-led learning, 10 role-based tracks, two onsite certification exams, and endless networking opportunities, Trailblazer Bootcamp is the place to build expertise and accelerate your path to certification.</p>
									</div>
								</div>
							</div>
						</div>

						<div class="panel panel-default">
							<div class="panel-heading" role="tab" id="collapse-action-2">
								<h4 class="panel-title">
									<a class="collapsed" role="button" data-toggle="collapse" data-parent="#faq-accordion" href="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
										<span class="glyphicon glyphicon-plus-sign"></span><span class="glyphicon glyphicon glyphicon-minus-sign"></span>
										<div class="panel-full">Who should come to Trailblazer Bootcamp?</div>
									</a>
								</h4>
							</div>
							<div id="collapse-2" class="panel-collapse collapse" role="tabpanel" aria-labelledby="collapse-action-2">
								<div class="panel-content">
									<div class="panel-row">
										<p>Trailblazer Bootcamp offers a variety of role-based tracks including: </p>
										<ul>
											<li>Administrator</li>
											<li>Developer</li>
											<li>Architect</li>
											<li>Data Rockstar</li>
											<li>Marketer</li>
										</ul>
										<p>We also welcome anyone who wants to explore new areas of interest in Salesforce, MuleSoft, and Tableau.</p>
									</div>
								</div>
							</div>
						</div>

						<div class="panel panel-default">
							<div class="panel-heading" role="tab" id="collapse-action-3">
								<h4 class="panel-title">
									<a class="collapsed" role="button" data-toggle="collapse" data-parent="#faq-accordion" href="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
										<span class="glyphicon glyphicon-plus-sign"></span><span class="glyphicon glyphicon glyphicon-minus-sign"></span>
										<div class="panel-full">I’m planning to attend Trailblazer Bootcamp. Which track should I take?</div>
									</a>
								</h4>
							</div>
							<div id="collapse-3" class="panel-collapse collapse" role="tabpanel" aria-labelledby="collapse-action-3">
								<div class="panel-content">
									<div class="panel-row">
										<p>Each track aligns with a specific role. Take a look at the detailed descriptions of our role-based tracks to see which suits you best. If you have questions, you can post them in the <a href="https://trailhead.salesforce.com/trailblazer-community/groups/0F94S000000kKjZSAU" target="_blank">Trailblazer Bootcamp Atlanta</a> Community Group.</p>
									</div>
								</div>
							</div>
						</div>

						<div class="panel panel-default">
							<div class="panel-heading" role="tab" id="collapse-action-4">
								<h4 class="panel-title">
									<a class="collapsed" role="button" data-toggle="collapse" data-parent="#faq-accordion" href="#collapse-4" aria-expanded="false" aria-controls="collapse-4">
										<span class="glyphicon glyphicon-plus-sign"></span><span class="glyphicon glyphicon glyphicon-minus-sign"></span>
										<div class="panel-full">Is there prework for Trailblazer Bootcamp?</div>
									</a>
								</h4>
							</div>
							<div id="collapse-4" class="panel-collapse collapse" role="tabpanel" aria-labelledby="collapse-action-4">
								<div class="panel-content">
									<div class="panel-row">
										<p>Yes. To ensure the best learning experience possible, we’ve identified some prework for you to complete before you arrive at Trailblazer Bootcamp. Navigate to your track <a href="https://trailhead.salesforce.com/help?article=Trailblazer-Bootcamp-Prework" target="_blank">here</a> and click on the prework link for your track. BONUS: You’ll earn the exclusive Trailblazer Bootcamp Atlanta Community Badge when you complete your prework.</p>
									</div>
								</div>
							</div>
						</div>

						<div class="panel panel-default">
							<div class="panel-heading" role="tab" id="collapse-action-5">
								<h4 class="panel-title">
									<a class="collapsed" role="button" data-toggle="collapse" data-parent="#faq-accordion" href="#collapse-5" aria-expanded="false" aria-controls="collapse-5">
										<span class="glyphicon glyphicon-plus-sign"></span><span class="glyphicon glyphicon glyphicon-minus-sign"></span>
										<div class="panel-full">Will computers be provided at Trailblazer Bootcamp?</div>
									</a>
								</h4>
							</div>
							<div id="collapse-5" class="panel-collapse collapse" role="tabpanel" aria-labelledby="collapse-action-5">
								<div class="panel-content">
									<div class="panel-row">
										<p>No. Computers are not provided at Trailblazer Bootcamp, so attendees will need to bring their own device. </p>
									</div>
								</div>
							</div>
						</div>

						<div class="panel panel-default">
							<div class="panel-heading" role="tab" id="collapse-action-6">
								<h4 class="panel-title">
									<a class="collapsed" role="button" data-toggle="collapse" data-parent="#faq-accordion" href="#collapse-6" aria-expanded="false" aria-controls="collapse-6">
										<span class="glyphicon glyphicon-plus-sign"></span><span class="glyphicon glyphicon glyphicon-minus-sign"></span>
										<div class="panel-full">Do I need to configure anything on my computer to get it set up for Trailblazer Bootcamp? </div>
									</a>
								</h4>
							</div>
							<div id="collapse-6" class="panel-collapse collapse" role="tabpanel" aria-labelledby="collapse-action-6">
								<div class="panel-content">
									<div class="panel-row">
										<p>Yes. Taking an exam on your personal computer requires some setup. Follow the steps <a href="https://trailhead.salesforce.com/help?article=Guide-to-Trailblazer-Bootcamp" target="_blank">here</a> to get your computer exam-ready. </p>
									</div>
								</div>
							</div>
						</div>

						<div class="panel panel-default">
							<div class="panel-heading" role="tab" id="collapse-action-7">
								<h4 class="panel-title">
									<a class="collapsed" role="button" data-toggle="collapse" data-parent="#faq-accordion" href="#collapse-7" aria-expanded="false" aria-controls="collapse-7">
										<span class="glyphicon glyphicon-plus-sign"></span><span class="glyphicon glyphicon glyphicon-minus-sign"></span>
										<div class="panel-full">Do I have to take an exam during Trailblazer Bootcamp?</div>
									</a>
								</h4>
							</div>
							<div id="collapse-7" class="panel-collapse collapse" role="tabpanel" aria-labelledby="collapse-action-7">
								<div class="panel-content">
									<div class="panel-row">
										<p>Exams are optional. You may choose to receive a voucher and take your exam(s) after bootcamp. Vouchers will be sent via email post-event, and exam(s) must be taken before August 30, 2023, at 11:59 PM EST. </p>
									</div>
								</div>
							</div>
						</div>

						<div class="panel panel-default">
							<div class="panel-heading" role="tab" id="collapse-action-8">
								<h4 class="panel-title">
									<a class="collapsed" role="button" data-toggle="collapse" data-parent="#faq-accordion" href="#collapse-8" aria-expanded="false" aria-controls="collapse-8">
										<span class="glyphicon glyphicon-plus-sign"></span><span class="glyphicon glyphicon glyphicon-minus-sign"></span>
										<div class="panel-full">Is there a Community Group for Trailblazer Bootcamp Atlanta?</div>
									</a>
								</h4>
							</div>
							<div id="collapse-8" class="panel-collapse collapse" role="tabpanel" aria-labelledby="collapse-action-8">
								<div class="panel-content">
									<div class="panel-row">
										<p>Yes. Get updates and post questions in the <a target="_blank" href="https://trailhead.salesforce.com/trailblazer-community/groups/0F94S000000kKjZSAU">Trailblazer Bootcamp Atlanta</a> Community Group.</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<h4 class="faqH4">Registration</h4>
					<div class="panel-group faq-panel" id="faq-accordion" role="tablist" aria-multiselectable="true">

						<div class="panel panel-default">
							<div class="panel-heading" role="tab" id="reg-1">
								<h4 class="panel-title">
									<a class="collapsed" role="button" data-toggle="collapse" data-parent="#reg-accordion2" href="#collapse-r1" aria-expanded="false" aria-controls="collapse-r1">
										<span class="glyphicon glyphicon-plus-sign"></span><span class="glyphicon glyphicon glyphicon-minus-sign"></span>
										<div class="panel-full">What is the cost of the Trailblazer Bootcamp pass?</div>
									</a>
								</h4>
							</div>
							<div id="collapse-r1" class="panel-collapse collapse" role="tabpanel" aria-labelledby="reg-1">
								<div class="panel-content">
									<div class="panel-row">
										<p>Early bird pricing of $2,599 is available until 11:59 PM EST on February 28, 2023. Thereafter, the full Trailblazer Bootcamp pass cost is $2,999. Accommodations are not included in the price of the Trailblazer Bootcamp pass and may be purchased separately through the registration process.</p>
									</div>
								</div>
							</div>
						</div>

						<div class="panel panel-default">
							<div class="panel-heading" role="tab" id="reg-2">
								<h4 class="panel-title">
									<a class="collapsed" role="button" data-toggle="collapse" data-parent="#reg-accordion2" href="#collapse-r2" aria-expanded="false" aria-controls="collapse-r2">
										<span class="glyphicon glyphicon-plus-sign"></span><span class="glyphicon glyphicon glyphicon-minus-sign"></span>
										<div class="panel-full">Are hotel blocks available for the event?</div>
									</a>
								</h4>
							</div>
							<div id="collapse-r2" class="panel-collapse collapse" role="tabpanel" aria-labelledby="reg-2">
								<div class="panel-content">
									<div class="panel-row">
										<p>Yes. Salesforce has reserved hotel blocks at the <a target="_blank" href="https://www.marriott.com/en-us/hotels/atlbc-atlanta-marriott-buckhead-hotel-and-conference-center/overview/">Atlanta Marriott Buckhead Hotel & Conference Center</a>. This limited block of rooms is available at a pre-negotiated rate while supplies last. You’ll have the opportunity to book your room at the end of your registration process.</p>
									</div>
								</div>
							</div>
						</div>

						<div class="panel panel-default">
							<div class="panel-heading" role="tab" id="reg-3">
								<h4 class="panel-title">
									<a class="collapsed" role="button" data-toggle="collapse" data-parent="#reg-accordion2" href="#collapse-r3" aria-expanded="false" aria-controls="collapse-r3">
										<span class="glyphicon glyphicon-plus-sign"></span><span class="glyphicon glyphicon glyphicon-minus-sign"></span>
										<div class="panel-full">How do I request a visa invitation?</div>
									</a>
								</h4>
							</div>
							<div id="collapse-r3" class="panel-collapse collapse" role="tabpanel" aria-labelledby="reg-3">
								<div class="panel-content">
									<div class="panel-row">
										<p>You can request a visa invitation letter during the registration process. Please note that you must complete your registration to secure your visa invitation.</p>
									</div>
								</div>
							</div>
						</div>

						<div class="panel panel-default">
							<div class="panel-heading" role="tab" id="reg-4">
								<h4 class="panel-title">
									<a class="collapsed" role="button" data-toggle="collapse" data-parent="#reg-accordion2" href="#collapse-r4" aria-expanded="false" aria-controls="collapse-r4">
										<span class="glyphicon glyphicon-plus-sign"></span><span class="glyphicon glyphicon glyphicon-minus-sign"></span>
										<div class="panel-full">What is the dress code?</div>
									</a>
								</h4>
							</div>
							<div id="collapse-r4" class="panel-collapse collapse" role="tabpanel" aria-labelledby="reg-4">
								<div class="panel-content">
									<div class="panel-row">
										<p>Trailblazer Bootcamp attire is business casual. Pack layers and check the weather before you arrive.</p>
									</div>
								</div>
							</div>
						</div>

						<div class="panel panel-default">
							<div class="panel-heading" role="tab" id="reg-5">
								<h4 class="panel-title">
									<a class="collapsed" role="button" data-toggle="collapse" data-parent="#reg-accordion2" href="#collapse-r5" aria-expanded="false" aria-controls="collapse-r5">
										<span class="glyphicon glyphicon-plus-sign"></span><span class="glyphicon glyphicon glyphicon-minus-sign"></span>
										<div class="panel-full">What is the cancellation policy?</div>
									</a>
								</h4>
							</div>
							<div id="collapse-r5" class="panel-collapse collapse" role="tabpanel" aria-labelledby="reg-5">
								<div class="panel-content">
									<div class="panel-row">
										<p>Cancellation Policy:</p>
										<ul>
											<li>Cancellation requests must be received in writing.</li>
											<li>Cancellations are <b>final</b>.</li>
											<li>Cancellation/refund deadlines apply:
												<ul>
													<li>Full refund: Cancellation must be received by 11:59 PM EST on March 31, 2023. </li>
													<li>50% refund: Cancellation must be received before 11:59 PM EST on April 15, 2023.</li>
													<li>No refunds will be given after 12:00 AM EST on April 16, 2023.</li>
													<li>Failure to check in at the event does not constitute cancellation and will not give rise to a refund or a credit.</li>
													<li><a target="_blank" href="https://success.salesforce.com/Ev_SiteTOS">Event Terms of Service</a></li>
												</ul>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>

						<div class="panel panel-default">
							<div class="panel-heading" role="tab" id="reg-6">
								<h4 class="panel-title">
									<a class="collapsed" role="button" data-toggle="collapse" data-parent="#reg-accordion2" href="#collapse-r6" aria-expanded="false" aria-controls="collapse-r6">
										<span class="glyphicon glyphicon-plus-sign"></span><span class="glyphicon glyphicon glyphicon-minus-sign"></span>
										<div class="panel-full">Who can I contact for assistance with registering for Trailblazer Bootcamp?</div>
									</a>
								</h4>
							</div>
							<div id="collapse-r6" class="panel-collapse collapse" role="tabpanel" aria-labelledby="reg-6">
								<div class="panel-content">
									<div class="panel-row">
										<p>Please contact the Trailblazer Bootcamp Registration and Housing team: </p>
										<ul>
											<li>U.S./Canada: +18446101363 </li>
											<li>International:+14159159075 </li>
											<li>Email us: <a href="mailto:trailblazerbc2023atl@regsvc.com">trailblazerbc2023atl@regsvc.com</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>

						<div class="panel panel-default">
							<div class="panel-heading" role="tab" id="reg-7">
								<h4 class="panel-title">
									<a class="collapsed" role="button" data-toggle="collapse" data-parent="#reg-accordion2" href="#collapse-r7" aria-expanded="false" aria-controls="collapse-r7">
										<span class="glyphicon glyphicon-plus-sign"></span><span class="glyphicon glyphicon glyphicon-minus-sign"></span>
										<div class="panel-full">Does Trailblazer Bootcamp have a Code of Conduct?</div>
									</a>
								</h4>
							</div>
							<div id="collapse-r7" class="panel-collapse collapse" role="tabpanel" aria-labelledby="reg-7">
								<div class="panel-content">
									<div class="panel-row">
										<p>Yes, check out our <a target="_blank" href="https://success.salesforce.com/ev_sitecoc">Code of Conduct</a>. Have a safe and fun conference!</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section class="container-content">
					<div class="center">
						<img class="logo logoInternal" src="https://developer.salesforce.com/resources2/bootcamps2022/Atlanta/Images/home/2023-Bootcamp-Seal_Atlanta_Color.png" />
					</div>
				</section>

				<div class="characters schedule-bg">
					<img src="https://developer.salesforce.com/resources2/bootcamps2022/Atlanta/Images/faq/FAQ.png" />
				</div>
        </AtlantaTemplate>
    )
}

export default AtlantaLocation
